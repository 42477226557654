import React from 'react'
import { Link } from 'react-router-dom'

const Pricing = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }


    const pricing = [
        {
            fIcon: 'fi flaticon-medal',
            li1: 'Video Editing',
            li2: '24/7 Support available',
            li3: 'Instagram $ Youtube Video Editing',
            li4: 'Shorts & long',
            title: 'Basic Pack',
            link: '/contact',
            price:'Ask for quatation',
        },
        {
            fIcon: 'fi flaticon-stats',
            li1: 'Youtube management',
            li2: '24/7 Support available',
            li3: '10x your views in 30 days or keep working for free',
            li4: 'Only YouTube',
            title: 'Standard Pack',
            link: '/contact',
            price:'Ask for quatation',
        },
        {
            fIcon: 'fi flaticon-trophy',
            li1: 'All social media',
            li2: '24/7 Support available',
            li3: '10x your views in 30 days or keep working for free',
            li4: 'All social media include',
            title: 'Extended Pack',
            link: '/contact',
            price:'Ask for quatation',
        },


    ]


    return (
        <section className="pricing-section section-padding">
            <div className="container">
                <div className="pricing-items">
                    <div className="row">
                    <h2 className="team-headline">Pricing</h2>
                        {pricing.map((pricing, ptem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={ptem}>
                                <div className="pricing-item">
                                    <h2>{pricing.title}</h2>
                                    <div className="price-price">
                                        <h3><span>{pricing.price}</span></h3>
                                    </div>
                                    <ul>
                                        <li>{pricing.li1}</li>
                                        <li>{pricing.li2}</li>
                                        <li>{pricing.li3}</li>
                                        <li>{pricing.li4}</li>
                                    </ul>
                                    <Link className="btn-style-2" onClick={ClickHandler} to={pricing.link}>Choose Plan</Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Pricing;