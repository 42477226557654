import React, { useEffect } from "react";

const LongFormVideoEditing = ({ limit }) => {
  const longFormVideos = [
    "h0o587hxss",
    "rz78pjson2",
    "f3qz9lmw5c",
    "huftscjv2a",
    "mrlmauhihb",
    "1xgnqxelq5", 
    "tq4vig7u1a"
  ];

  // Dynamically load Wistia embed scripts
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Limit the number of videos based on the prop
  const displayedVideos = longFormVideos.slice(0, limit);

  return (
    <section className="long-form-editing">
      <div className="container">
        <h1>
          Long Form <span>Video</span> Editing
        </h1>
        <p>
          In-depth, long-form videos perfect for tutorials, detailed storytelling, and long engagement.
        </p>
        <div className="long-form-grid">
          {displayedVideos.map((videoId) => (
            <div
              key={videoId}
              className="wistia_responsive_padding_long"
            >
              <div
                className="wistia_responsive_wrapper_long"
              >
                <div
                  className={`wistia_embed wistia_async_${videoId} seo=true videoFoam=true`}
                  style={{ height: "100%", position: "relative", width: "100%" }}
                />
              </div>
            </div>
          ))}
          <button className="cta-button-long">GET STARTED</button>
        </div>
      </div>
    </section>
  );
};

export default LongFormVideoEditing;
