import React, { useEffect } from 'react';

const HeroSection = () => {
  useEffect(() => {
    const leftSideElement = document.getElementById("left-side");
    const personalElement = document.getElementById("personal");

    const handleMovement = (event) => {
      const clientX = event.touches ? event.touches[0].clientX : event.clientX;
      const newWidthPercentage = (clientX / window.innerWidth) * 100;
      leftSideElement.style.width = `${newWidthPercentage}%`;
    };

    const randomSign = () => 
      "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[Math.floor(Math.random() * 26)];

    const animateTextChange = (event) => {
      const targetElement = event.target;
      let iterations = 0;
      const interval = setInterval(() => {
        targetElement.innerText = targetElement.innerText
          .split("")
          .map((char, index) =>
            index < iterations ? targetElement.dataset.value[index] : randomSign()
          )
          .join("");
        if (iterations >= targetElement.dataset.value.length)
          clearInterval(interval);
        iterations++;
      }, 30);
    };

    const enhanceTextWithSpans = (id) => {
      const element = document.getElementById(id);
      const letters = element.innerText.split("");
      element.innerText = "";
      letters.forEach((letter) => {
        const span = document.createElement("span");
        span.className = "letter";
        span.innerText = letter;
        element.appendChild(span);
      });
    };

    if (leftSideElement) {
      document.addEventListener("mousemove", handleMovement);
      document.addEventListener("touchmove", handleMovement);
    }

    if (personalElement) {
      personalElement.addEventListener("mousemove", animateTextChange);
    }

    enhanceTextWithSpans("video");

    return () => {
      if (leftSideElement) {
        document.removeEventListener("mousemove", handleMovement);
        document.removeEventListener("touchmove", handleMovement);
      }

      if (personalElement) {
        personalElement.removeEventListener("mousemove", animateTextChange);
      }
    };
  }, []);

  return (
    <main >
      <section className="hero-wrapper">
        {/* Left Side */}
        <article id="left-side" className="hero-side">
          <h1 className="title">
            We're not <span data-value="VIDEO" id="video" className="cool">VIDEO</span> editor
          </h1>
          <a href="/" className="name" id="first-name" aria-label="First Name: Noob">Video Editor</a>
        </article>
        {/* Right Side */}
        <article id="right-side" className="hero-side">
          <h1 className="title">
            We create <span data-value="PERSONAL" id="personal" className="cool">PERSONAL</span> brand
          </h1>
          <a href="/" className="name" id="last-name" aria-label="Last Name: Coder">Personal Brand</a>
        </article>
      </section>
    </main>
  );
};

export default HeroSection;
