import React from 'react';
import './CaseStudy.css';

// Data for the testimonials
const testimonials = [
  {
    id: 1,
    avatar: 'https://assets.codepen.io/5126815/image-daniel.jpg',
    name: 'Daniel Clifford',
    status: 'Verified Graduate',
    title: `I received a job offer mid-course, and the subjects I learned were current, if not more so, in the company I joined. I honestly feel I got every penny's worth.`,
    quote: `I was an EMT for many years before I joined the bootcamp. I've been looking to make a transition and have heard some people who had an amazing experience here...`,
    className: 'testimonial-card--primary'
  },
  {
    id: 2,
    avatar: 'https://assets.codepen.io/5126815/image-jonathan.jpg',
    name: 'Jonathan Walters',
    status: 'Verified Graduate',
    title: 'The team was very supportive and kept me motivated',
    quote: `I started as a total newbie with virtually no coding skills. I now work as a mobile engineer for a big company. This was one of the best investments I've made in myself.`,
    className: 'testimonial-card--secondary'
  },
  {
    id: 3,
    avatar: 'https://assets.codepen.io/5126815/image-jonathan.jpg',
    name: 'Jonathan Walters',
    status: 'Verified Graduate',
    title: 'The team was very supportive and kept me motivated',
    quote: `I started as a total newbie with virtually no coding skills. I now work as a mobile engineer for a big company. This was one of the best investments I've made in myself.`,
    className: 'testimonial-card--secondary'
  },
  {
    id: 4,
    avatar: 'https://assets.codepen.io/5126815/image-jonathan.jpg',
    name: 'Jonathan Walters',
    status: 'Verified Graduate',
    title: 'The team was very supportive and kept me motivated',
    quote: `I started as a total newbie with virtually no coding skills. I now work as a mobile engineer for a big company. This was one of the best investments I've made in myself.`,
    className: 'testimonial-card--secondary'
  },
  {
    id: 5,
    avatar: 'https://assets.codepen.io/5126815/image-jonathan.jpg',
    name: 'Jonathan Walters',
    status: 'Verified Graduate',
    title: 'The team was very supportive and kept me motivated',
    quote: `I started as a total newbie with virtually no coding skills. I now work as a mobile engineer for a big company. This was one of the best investments I've made in myself.`,
    className: 'testimonial-card--secondary'
  },
];

const TestimonialCard = ({ avatar, name, status, title, quote, className }) => (
  <div className={`testimonial-card ${className}`}>
    <div className="testimonial-card__header">
      <img src={avatar} alt={name} className="testimonial-card__avatar" />
      <div className="testimonial-card__details">
        <div className="testimonial-card__name">{name}</div>
        <div className="testimonial-card__status">{status}</div>
      </div>
    </div>
    <div className="testimonial-card__title">{title}</div>
    <div className="testimonial-card__quote">{quote}</div>
  </div>
);

// Main App component
function CaseStudy() {
  return (
    <div className="testimonial-grid">
      {testimonials.map(testimonial => (
        <TestimonialCard key={testimonial.id} {...testimonial} />
      ))}
    </div>
  );
}

export default CaseStudy;
