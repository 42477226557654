import React, { useState } from 'react';
import badal from "../../images/team/team3.jpg";
import vishal from "../../images/team/team1.jpg";

const teamMembers = [
  {
    id: 'm1',
    name: 'Badal Vishwakarma',
    title: 'Founder',
    avatar: badal,
    bio: `Badal Vishwakarma is the visionary founder of CloudSpax, where he plays a crucial role in outreach and client acquisition. With a strong background in computer applications, having completed his MCA, Badal has a keen eye for identifying opportunities and connecting with clients who seek quality digital services. Over the past year, under his leadership, CloudSpax has grown steadily, delivering exceptional results for a diverse range of clients. His dedication to building relationships and expanding the agency’s reach is instrumental in the company’s success.`,
  },
  {
    id: 'm2',
    name: 'Vishal Jatav',
    title: 'Co-founder',
    avatar: vishal,
    bio: `Vishal, the co-founder of CloudSpax, is the driving force behind the seamless delivery of the agency’s services. With an MCA degree, he brings technical expertise and a detail-oriented approach to every project. Over the last year, Vishal has been committed to ensuring that CloudSpax consistently meets and exceeds client expectations. His focus on quality and client satisfaction has helped the agency build a strong reputation for delivering outstanding results. Vishal’s leadership in operations ensures that every client’s vision is realized to the highest standard..`,
  },
  // Add other team members here...
];

const TeamSection2 = () => {
  const [activeMember, setActiveMember] = useState('m1');

  const handleMemberChange = (event) => {
    setActiveMember(event.target.id);
  };

  return (
    <>
     <h2 className="team-headline">Meet Our Team</h2>
    <div className="wrapper">
      {/* Add the headline here */}
      
      
      <div className="team-wrapper">
        <div className="members">
          {teamMembers.map((member) => (
            <label
              key={member.id}
              htmlFor={member.id}
              className={`tile ${activeMember === member.id ? 'active' : ''}`}
            >
              <img src={member.avatar} alt={member.name} />
            </label>
          ))}
        </div>

        <div className="divider"></div>

        <div className="cards">
          <div className="card-wrapper">
            {teamMembers.map((member) => (
              <div key={member.id}>
                <input
                  type="radio"
                  id={member.id}
                  name="members"
                  checked={activeMember === member.id}
                  onChange={handleMemberChange}
                />
                <div className={`card ${activeMember === member.id ? 'active' : ''}`}>
                  <img src={member.avatar} className="avatar" alt={member.name} />
                  <div className="info">
                    <div className="name">{member.name}</div>
                    <div className="title">{member.title}</div>
                    <div className="bio">{member.bio}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default TeamSection2;
