import React, { useState, useEffect } from 'react';
import './Testimonial.css'; // Import your CSS file
import TestimonialItem from '../../api/testimonial';

const Testimonial = () => {
  const [activeQuote, setActiveQuote] = useState(1);
  const [activeDp, setActiveDp] = useState(1);

  // Function to update active testimonial
  const updateTestimonial = () => {
    setActiveQuote((prev) => (prev === TestimonialItem.length ? 1 : prev + 1));
    setActiveDp((prev) => (prev === TestimonialItem.length ? 1 : prev + 1));
  };

  useEffect(() => {
    // Check if the screen is mobile-sized
    const isMobile = window.innerWidth <= 767;

    if (isMobile) {
      const interval = setInterval(updateTestimonial, 3000);
      return () => clearInterval(interval); // Clean up the interval on unmount
    }
  }, []);

  useEffect(() => {
    // Handle hide/show logic for quotes
    document.querySelectorAll('.quote').forEach((el) => {
      el.classList.add('hide-top');
      el.classList.remove('show');
    });
    if (activeQuote !== null) {
      document.querySelector(`.quote-text-${activeQuote}`).classList.add('show');
      document.querySelector(`.quote-text-${activeQuote}`).classList.remove('hide-bottom');
    }
  }, [activeQuote]);

  useEffect(() => {
    // Handle hide/show logic for DP names
    document.querySelectorAll('.box-dpname').forEach((el) => {
      el.classList.add('hide-dp-top');
      el.classList.remove('look');
    });
    if (activeDp !== null) {
      document.querySelector(`.dp-name-${activeDp}`).classList.add('look');
      document.querySelector(`.dp-name-${activeDp}`).classList.remove('hide-dp-bottom');
    }
  }, [activeDp]);

  return (
    <section className="op-section op-eight-section section" id="section5">
      <div className="ocean-2">
        <div className="wave-2"></div>
        <div className="wave-2"></div>
      </div>
      <div className="section-eight">
        <div className="container">
          {/* Add the headline here */}
          <h2 className="testimonial-headline">What People Say About Us</h2>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-md-3 col-sm-3">
              <div className="container-pe-quote left">
                {TestimonialItem.slice(0, 3).map((testimonial) => (
                  <div
                    key={testimonial.id}
                    className={`pp-quote li-quote-${testimonial.id} ${activeQuote === testimonial.id ? 'active' : ''}`}
                    onClick={() => {
                      setActiveQuote(testimonial.id);
                      setActiveDp(testimonial.id);
                    }}
                  >
                    <img src={testimonial.image} alt={testimonial.name} />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="sec-eight-text-area">
                <div className="container-dp-name">
                  {TestimonialItem.map((testimonial) => (
                    <div
                      key={testimonial.id}
                      className={`box-dpname dp-name-${testimonial.id} ${activeDp === testimonial.id ? 'look' : 'hide-dp-top'}`}
                    >
                      <img src={testimonial.image} alt={testimonial.name} className='hero-image' />
                      <h1>{testimonial.name}</h1>
                    </div>
                  ))}
                </div>
                <div className="container-quote">
                  {TestimonialItem.map((testimonial) => (
                    <div
                      key={testimonial.id}
                      className={`quote quote-text-${testimonial.id} ${activeQuote === testimonial.id ? 'show' : 'hide-top'}`}
                    >
                      <img src={`http://nanaetben.fr/animation/assets/images/website/quote.png`} alt={`quote-${testimonial.id}`} />
                      <p>{testimonial.quote}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3">
              <div className="container-pe-quote right">
                {TestimonialItem.slice(3).map((testimonial) => (
                  <div
                    key={testimonial.id}
                    className={`pp-quote li-quote-${testimonial.id} ${activeQuote === testimonial.id ? 'active' : ''}`}
                    onClick={() => {
                      setActiveQuote(testimonial.id);
                      setActiveDp(testimonial.id);
                    }}
                  >
                    <img src={testimonial.image} alt={testimonial.name} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
