import pImg1 from '../images/project/img-1.jpg';
import pImg2 from '../images/project/img-2.jpg';
import pImg3 from '../images/project/img-3.jpg';
import pImg4 from '../images/project/img-4.jpg';
import pImg5 from '../images/project/img-5.jpg';
import pImg6 from '../images/project/img-5.jpg';
import pImg7 from '../images/project/img-6.jpg';
import pImg8 from '../images/project/img-7.jpg';
import pImg9 from '../images/project/img-8.jpg';
import pImg10 from '../images/project/img-9.jpg';
import pImg11 from '../images/project/img-10.jpg';
import pImg12 from '../images/project/img-11.jpg';

const Projects = [
 

  {
    Id: '1',
    client: "Sample work",
    niche:'youtuber',
    insta:'https://www.instagram.com/everycornerofworld',
    youtube:"#",
    doj:'29/01/2023',
    sub: 'Iman gadzhi style',
    link: 'https://www.youtube.com/embed/DyEP-tF3Kt4',
    description: 'this is a iman gadgi style video',
    filterName: 'fav',
  },
  {
    Id: '2',
    client: "Sample work",
    niche:'youtuber',
    insta:'#',
    youtube:"#",
    doj:'05/02/2023',
    sub: 'Iman gadzhi style',
    link: 'https://www.youtube.com/embed/c1V0_73YJ5o',
    description: 'this is a iman gadgi style video',
    filterName: 'fav',
  },

  {
    Id: '3',
    client: "Sagar shridesh",
    niche:'youtuber',
    insta:'#',
    youtube:"#",
    doj:'22/01/2023',
    sub: 'dhruve rather style',
    link: 'https://www.youtube.com/embed/ScfgAnd1RbM',
    description: 'We are helping client to create WordPress websites.',
    filterName: 'talking head',
  },
  {
    Id: '4',
    client: "sample work",
    niche:'youtuber',
    insta:'#',
    youtube:"#",
    doj:'22/01/2023',
    sub: 'Iman gadzhi style',
    link: 'https://www.youtube.com/embed/QxG6hBAzgzA',
    description: 'We are helping client to create WordPress websites.',
    filterName: 'talking head',
  },
  {
    Id: '6',
    client: "update and finance",
    niche:'youtuber',
    insta:'#',
    youtube:"#",
    doj:'22/01/2023',
    sub: 'Iman gadzhi style',
    link: 'https://www.youtube.com/embed/ln02JIoQKHo',
    description: 'We are helping client to create WordPress websites.',
    filterName: 'talking head',
  },

  {
    Id: '7',
    client: "sample work",
    niche:'youtuber',
    insta:'#',
    youtube:"#",
    doj:'22/01/2023',
    sub: 'Iman gadzhi style',
    link: 'https://www.youtube.com/embed/mWTBFlDp4fc',
    description: 'We are helping client to create WordPress websites.',
    filterName: 'talking head',
  },
  {
    Id: '8',
    client: "sample work",
    niche:'youtuber',
    insta:'#',
    youtube:"#",
    doj:'22/01/2023',
    sub: 'documentery',
    link: 'https://www.youtube.com/embed/sT5x-Lj1MPk',
    description: 'We are helping client to create WordPress websites.',
    filterName: 'Docs',
  },

  {
    Id: '8',
    client: "sample work",
    niche:'youtuber',
    insta:'#',
    youtube:"#",
    doj:'22/01/2023',
    sub: 'documentery',
    link: 'https://www.youtube.com/embed/6RB-tC44ezA',
    description: 'We are helping client to create WordPress websites.',
    filterName: 'Docs',
  },

  {
    Id: '8',
    client: "sample work",
    niche:'youtuber',
    insta:'#',
    youtube:"#",
    doj:'22/01/2023',
    sub: 'documentery',
    link: 'https://www.youtube.com/embed/RpBXht1Mq6U',
    description: 'We are helping client to create WordPress websites.',
    filterName: 'Docs',
  },

  {
    Id: '8',
    client: "sample work",
    niche:'youtuber',
    insta:'#',
    youtube:"#",
    doj:'22/01/2023',
    sub: 'documentery',
    link: 'https://www.youtube.com/embed/TgMOeMr-ssI',
    description: 'We are helping client to create WordPress websites.',
    filterName: 'Docs',
  },
 
];

const Shorts = [
  
  {
    Id: '1',
    pImg: pImg1,
    psImg: pImg12,
    title: 'Creative',
    slug: 'Creative',
    sub: '2022 - UX',
    link: 'https://www.youtube.com/embed/N-BZlo3GJfY',
    description: 'We are helping client to create WordPress websites.',
    filterName: 'talking head',
  },
  
  {
    Id: '2',
    pImg: pImg1,
    psImg: pImg12,
    title: 'Creative',
    slug: 'Creative',
    sub: '2022 - UX',
    link: 'https://www.youtube.com/embed/zxKt-Ddd9v4',
    description: 'We are helping client to create WordPress websites.',
    filterName: 'talking head',
  },
  // {
  //   Id: '3',
  //   pImg: pImg1,
  //   psImg: pImg12,
  //   title: 'Creative',
  //   slug: 'Creative',
  //   sub: '2022 - UX',
  //   link: 'https://www.youtube.com/embed/tPvJGnUR2iM',
  //   description: 'We are helping client to create WordPress websites.',
  //   filterName: 'creator',
  // },
  {
    Id: '4',
    pImg: pImg1,
    psImg: pImg12,
    title: 'Creative',
    slug: 'Creative',
    sub: '2022 - UX',
    link: 'https://www.youtube.com/embed/aUfC0gF1AqU',
    description: 'We are helping client to create WordPress websites.',
    filterName: 'podcast',
  },
  {
    Id: '5',
    pImg: pImg1,
    psImg: pImg12,
    title: 'Creative',
    slug: 'Creative',
    sub: '2022 - UX',
    link: 'https://www.youtube.com/embed/A_k7Xnb_-RA',
    description: 'We are helping client to create WordPress websites.',
    filterName: 'talking head',
  },
  {
    Id: '6',
    pImg: pImg1,
    psImg: pImg12,
    title: 'Creative',
    slug: 'Creative',
    sub: '2022 - UX',
    link: 'https://www.youtube.com/embed/Uz2kAj9qZxQ',
    description: 'We are helping client to create WordPress websites.',
    filterName: 'talking head',
  },
  {
    Id: '6',
    pImg: pImg1,
    psImg: pImg12,
    title: 'Creative',
    slug: 'Creative',
    sub: '2022 - UX',
    link: 'https://www.youtube.com/embed/dmQX5DFAvaA',
    description: 'We are helping client to create WordPress websites.',
    filterName: 'talking head',
  },
];

export { Projects, Shorts };
