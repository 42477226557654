// import React from 'react';
// import Services from '../../api/service'
// import { Link } from "react-router-dom";
// import shape from '../../images/service/arrow-shape.png'
// import { Slide } from "react-awesome-reveal";

// const ServiceSection = (props) => {
//     const ClickHandler = () => {
//         window.scrollTo(10, 0);
//     }

//     return (

//         <section className="service-section section-padding" id="service">
//             <div className="container">
//                 <div className="row">
//                     <div className="col-lg-6">
//                         <div className="service-left-text">
//                             <span>What service we offer</span>
//                             <h2>The way we do things in our line of work
//                                  {/* <i> <span>WORK</span> <img 
//                                 src={shape} alt="" /></i> */}
//                                 </h2>
                                 
//                             <p>We are helping client
//                                         to build thier personal brand thorugh social media with our stretegy and editing..</p>
//                                         <Link onClick={ClickHandler} to="/services" className="read-more">Read case study</Link>
                            
//                         </div>
//                     </div>
//                     <div className="col-lg-6">
//                         <div className="service-wrap">
//                             <div className="row">
//                                 {Services.slice(0, 4).map((service, srv) => (
//                                     <div className="col col-lg-6 col-md-6 col-12" key={srv}>
//                                         <Slide direction='up' triggerOnce={'false'}>
//                                             <div className="service-item">
//                                                 <i className='image-icon'><img src={service.sIcon} alt="" /></i>
//                                                 <h2><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.title}</Link></h2>
//                                                 <p>{service.description}</p>
//                                             </div>
//                                         </Slide>
//                                     </div>
//                                 ))}

//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </section>
//     );
// }

// export default ServiceSection;
import React from 'react';
import Services from '../../api/service'; // Import the Services data

const ServiceSection = () => {
  return (
    <section className="container">
      <div className="headline">
        <h1>What We Offer</h1>
      </div>
      <section className="card__container">
        {Services.map((service, index) => (
          <div className="card__bx" key={index} style={{ '--clr': '#c1c7c6' }}> {/* Set color to white */}
            <div className="card__data">
              <div className="card__icon">
                <img src={service.sIcon} alt={`${service.title} icon`} /> {/* Use service.sIcon for the icon */}
              </div>
              <div className="card__content">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                <a href={`/service-single/${service.slug}`}>Read More</a> {/* Link to the detailed page */}
              </div>
            </div>
          </div>
        ))}
      </section>
    </section>
  );
};

export default ServiceSection;

