import pamela from '../images/testimonial/pamela.jpg'
import sandeep from '../images/testimonial/sandeep.jpg'
import harsh from '../images/testimonial/harsh nahar.jpg'
import sanyam from '../images/testimonial/sayyam.jpg'
import swIcon5 from '../images/service/icon-5.png'



const TestimonialItem = [
    {
        id: '1',
        image:pamela,
        name: 'Pamela Mukherjii',
        quote: 'I’m so happy with the video editing service from CloudSpax. They took my basic clips and turned them into something really special. The turnaround time was quick, and they were very professional throughout the process.'
    },
    {
        id: '2',
        image:sandeep,
        name: 'Sandeep Dhouni',
        quote: 'Working with CloudSpax for video editing was a breeze. They really understood my vision and brought it to life. The quality of the edits was top-notch, and they made sure I was satisfied with every detail.'
    },
    {
        id: '3',
        image:harsh,
        name: 'Harsh Nahar',
        quote: 'Really pleased with the editing service from badal and vishal. They understood what I needed and delivered on time. The quality of the video was excellent, and I felt confident sharing it with my audience they help every thing from starting to ending it doestnt matter monry'
    },
    {
        id: '4',
        image:sanyam,
        name: 'Sanyam(SM cricket)',
        quote: 'I had a great experience with badal and vishal. They were very responsive and made sure my project was done on time. The quality of work was excellent, and I felt well taken care of throughout the process.'
    },
   
]    

export default TestimonialItem;