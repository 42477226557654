import React, { useState, useEffect, useRef } from 'react';
import ShortFormVideoEditing from './ShortfromPortfilo';
import LongFormVideoEditing from './LongfromPortfolio';

const ProjectSectionS5 = () => {



  return (
<>
    <ShortFormVideoEditing/>
    <LongFormVideoEditing/>
    </>
  );
};

export default ProjectSectionS5;
