import React, { useEffect } from "react";

const ShortFormVideoEditing = ({ limit }) => {
  const shortFormVideos = [
    "z8chu5ylz0",
    "ih28j2ystj",
    "uumfiu7mdt",
    "6wh3zgtcq9",
    "sxme0iyjlu",
    "ai9ufem2ij",
    "hhxpli6b9j",
    "yenet1xeso",
    "ptci4oqum2"
  ];

  // Dynamically load Wistia embed scripts
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://fast.wistia.com/assets/external/E-v1.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Limit the number of videos based on the prop
  const displayedVideos = shortFormVideos.slice(0, limit);

  return (
    <section className="short-form-editing">
      <div className="container">
        <h1>
          Short Form <span>Video</span> Editing
        </h1>
        <p>
          High-impact, short-form videos for platforms like TikTok, Instagram, and YouTube Shorts. Enhance your brand with concise, engaging content.
        </p>
        <div className="wistia-grid">
          {displayedVideos.map((videoId) => (
            <div
              key={videoId}
              className="wistia_responsive_padding"
              style={{ padding: "177.78% 0 0 0", position: "relative" }}
            >
              <div
                className="wistia_responsive_wrapper"
                style={{
                  height: "100%",
                  left: "0",
                  position: "absolute",
                  top: "0",
                  width: "100%",
                }}
              >
                <div
                  className={`wistia_embed wistia_async_${videoId} seo=true videoFoam=true`}
                  style={{ height: "100%", position: "relative", width: "100%" }}
                />
              </div>
            </div>
          ))}
        </div>
        <button className="cta-button">GET STARTED</button>
      </div>
    </section>
  );
};

export default ShortFormVideoEditing;
