import swIcon1 from '../images/service/script.png'
import swIcon2 from '../images/service/video-camera.png'
import swIcon3 from '../images/service/video-editor (1).png'
import swIcon4 from '../images/service/thumbnail.png'
import swIcon5 from '../images/service/seo.png'

const Services = [
    {
        Id: '1',
        sIcon: swIcon1,
        title: 'Script',
        slug: 'script',
        description: 'Crafting compelling scripts that capture your audience\'s attention and drive engagement across all social platforms.',
        des2: 'Creating scripts that captivate and engage, ensuring your social media content resonates with your target audience.',
    },
    {
        Id: '2',
        sIcon: swIcon2,
        title: 'Shoot',
        slug: 'shoot',
        description: 'Delivering professional video shoots tailored to highlight your brand and message, enhancing your visual storytelling.',
        des2: 'We specialize in capturing high-quality visuals, bringing your brand\'s story to life with expert video shoots for all platforms.',
    },
    {
        Id: '3',
        sIcon: swIcon3,
        title: 'Video Editing',
        slug: 'video-editing',
        description: 'Transforming raw footage into polished, engaging videos that amplify your brand\'s message and captivate your audience.',
        des2: 'Expert video editing services to refine your content, ensuring a professional and captivating final product that stands out.',
    },
    {
        Id: '4',
        sIcon: swIcon4,
        title: 'Thumbnail Design',
        slug: 'thumbnail-design',
        description: 'Creating eye-catching thumbnails that increase click-through rates and make your videos stand out on platforms like YouTube.',
        des2: 'Designing impactful thumbnails that attract viewers and boost your content\'s visibility across digital platforms.',
    },
    {
        Id: '5',
        sIcon: swIcon5,
        title: 'SEO',
        slug: 'seo',
        description: 'Optimizing your content for search engines, ensuring greater visibility and driving more traffic to your social media channels.',
        des2: 'Comprehensive SEO strategies that boost your content\'s searchability and enhance your online presence across various platforms.',
    },
]

export default Services;
