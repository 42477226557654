import React from 'react';
import { Link } from 'react-router-dom'; // If you need to use react-router for internal links

const Footer = () => {
    return (
        <footer className="padding_4x">
            <div className="flex">
                <section className="flex-content padding_1x">
                    <h3>Services</h3>
                    <a href="/video-editing">Video Editing</a>
                    <a href="Thumbnail-Desgin">Thumbnail Design</a>
                    <a href="/seo">SEO</a>
                    <a href="/script">Shoot and Script</a>
                </section>
                <section className="flex-content padding_1x">
                    <h3>Quick Links</h3>
                    <a href="/portfolio">Portfolio</a>
                    <a href="/contact">Contact</a>
                    <a href="#">Terms of Service</a>
                </section>
                {/* <section className="flex-content padding_1x">
                    <h3>Features</h3>
                    <a href="#">Jobs</a>
                    <a href="#">Brand Assets</a>
                    <a href="#">Investor Relations</a>
                    <a href="#">Terms of Service</a>
                </section> */}
                <section className="flex-content padding_1x">
                    <h3>About us</h3>
                    <a href="/blog">blog</a>
                    <a href="/team">team</a>
                    <a href="/service">service</a>
                </section>
                <section className="flex-content padding_1x">
                    <h3>Newsletter</h3>
                    <p>You can trust us. We only send promo offers.</p>
                    <fieldset className="fixed_flex">
                        <input type="email" name="newsletter" placeholder="Your Email Address" />
                        <button className="btn btn_2">Subscribe</button>
                    </fieldset>
                </section>
            </div>
            <div className="flex">
                <section className="flex-content padding_1x">
                    <p>Copyright ©2024 All rights reserved || Cloudspax</p>
                </section>
                <section className="flex-content padding_1x">
                    <a href="https://www.facebook.com/profile.php?id=100073667690196&mibextid=ZbWKwL" target="_blank"><i className="fa fa-facebook"></i></a>
                    <a href="https://www.instagram.com/thebadall?igsh=MTQxZmdkbmo4OXQ2dQ=="><i className="fa fa-instagram"></i></a>
                    <a href="https://cloudspax.com/" target="_blank"><i className="fa fa-dribbble"></i></a>
                    <a href="https://www.linkedin.com/in/badal-vishwakarma-a474a91b1/" target="_blank"><i className="fa fa-linkedin" target="_blank"></i></a>
                </section>
            </div>
        </footer>
    );
};

export default Footer;
