import timg1 from '../images/team/team1.jpg'
import timg2 from '../images/team/team3.jpg'
import timg3 from '../images/team/team2.jpg'
import timg4 from '../images/team/img-4.jpg'
import timg5 from '../images/team/img-5.jpg'
import timg6 from '../images/team/img-6.jpg'
import timg7 from '../images/team/img-7.jpg'
import timg8 from '../images/team/img-8.jpg'
import timg9 from '../images/team/img-9.jpg'


const Teams = [
   {
      Id: '1',
      tImg: timg1,
      name: 'Vishal Jatav',
      slug: 'vishal-jatav',
      title: 'Video editor',
   },
   {
      Id: '2',
      tImg: timg2,
      name: 'Badal Vishwakarma',
      slug: 'badal-vishwakarma',
      title: 'Graphic Designer',
   },
   
]

export default Teams;